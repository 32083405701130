<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <!-- Loading spinner -->
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          class="my-10"
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <!-- Dialog content -->
      <v-card v-else background-color="#f2f2f2">
        <v-card-title
          class="py-0 pt-7"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">View All Assembly</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            large
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleAllViewAssemblyModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
        <template>
  <tr v-for="(group, groupIndex) in assembliList" :key="groupIndex">
    <td class="text-left">
      <div>
        <span v-if="group.player_list != null" style="font-size: 20px">
          {{ group.assemble_city }}, {{ group.assemble_state }}-FA({{ group.sequence }})
          <span v-for="(player, index) in group.player_list" :key="index">
            {{ player.name }},
          </span>
        </span>
        <span v-else style="font-size: 20px">
          {{ group.assemble_city }}, {{ group.assemble_state }}-FA({{ group.sequence }}){{ group.team_name }}
        </span>
      </div>
      <br />
      <b>Date:</b> {{ group.assemble_date_2 }}
      <br />
      <b>Time:</b> {{ group.start_time_str }} - {{ group.end_time_str }}
      <br />
      <b>No. Of School:</b> {{ group.total_assemblies }}
      <br />
      <b>No. Of Students:</b> {{ group.total_student }}
      <br /><br />
      <b>Meeting Time:</b> {{ group.meeting_time_str }}
      <br />
      <b>Meeting Place:</b> {{ group.school }}
      <br />
      <b>Meeting Address:</b> {{ group.address }}
      <br />
      <b>Driver Name:</b> {{ group.driver_name }}
      <br />
      <b>Driver Cell:</b> {{ group.phone }}
      <br /><br />
      <b>Description:</b>
      <div v-html="group.note" style="line-height: 1.2"></div>
      <br />
      <div v-for="(group1, index) in group.assemble_school_groups" :key="index">
        <strong>School {{ index + 1 }}</strong> <br />
        <b>School Name:</b> {{ group1.school_name }} <br />
        <b>School Address:</b> {{ group1.school_address }} <br />
        <b>Start Time:</b> {{ group1.start_time_str }} <br />
        <b>End Time:</b> {{ group1.end_time_str }} <br />
        <b>Location:</b> {{ group1.location_in_school }} <br />
        <b>Number of Students:</b> {{ group1.number_of_students }} <br />
        <b>Principal Name:</b> {{ group1.principal_name }} <br />
        <b>Principal Email:</b> {{ group1.principal_email }} <br />
        <b>Principal Phone:</b> {{ group1.principal_phone }} <br />
        <b>Grade:</b> {{ group1.grades }} <br />
        <b>Visit:</b> {{ group1.visit }} <br />
       <div v-html="'<strong>Note:</strong> ' + group1.note"></div> <br /><br />
      </div>
    </td>
  </tr>
</template>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import { ASSEMBLY_SCHEDUAL_API_LIST } from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "NoteModal",
  data() {
    return {
      rules: RULES,
      loading: false,
        assembliList: [], // For storing assembly items
      assembleSchoolGroups: [], // Initialize to store school groups
      assemblyDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      showGetters: "gameManagement/getViewAllAssemblyShow",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.showGetters;
      },
      set(value) {
        return this.toggleAllViewAssemblyModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.viewallmessageModal.type;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAllViewAssemblyModal: "gameManagement/toggleAllViewAssemblyModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getAssemblyData();
    },
    closeModal() {
      this.loading = false;
    },
    getAssemblyData() {
      this.loading = true;
      const successHandler = (res) => {
        this.assembliList = res.data.assemble_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] =
        this.$store.state.gameManagement.viewallmessageModal.gameId;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    reload() {
      location.reload();
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
